import React, { useContext } from 'react';
import AdvisoryImg from 'assets/Intro/advisory.avif';
import AdvisoryImgMo from 'assets/Intro/advisory_mo.avif';
import Container from 'components/Common/Container';
import { motion } from 'framer-motion';
import { END_ANIMATION_PROPERTY, START_ANIMATION_PROPERTY, VIEWPORT_PROPERTY } from 'constants/animation-properties';
import { GlobalContext } from 'App';
import { list } from './data';

const Advisory = () => {
    const { isTablet } = useContext(GlobalContext);
    return (
        <section id="intro_advisory" className={`${isTablet ? 'pt-14' : 'py-33'} bg-white`}>
            <Container className='flex flex-col items-center px-4'>
                <motion.button
                    className={`bg-[#0D0D93B2] pointer-events-none border-[#0D0D93] rounded-3xl py-2 px-6 mb-8 text-white font-bold ${isTablet ? 'text-base' : 'text-2xl'}`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    자문 위원단 & 강사
                </motion.button>

                <motion.p
                    className={`${isTablet ? 'text-32 text-center leading-10' : 'text-40'} text-black font-bold  mb-10`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    제약바이오산업연구소는{isTablet && <br />} 산업 최고의 전문가들과{isTablet && <br />} 함께합니다.
                </motion.p>

                <hr className="text-black my-8 border-t-4 w-8 mx-auto" />

                <motion.p
                    className={`${isTablet ? 'text-base' : 'text-xl'} text-black font-medium mb-15 leading-8 text-center`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    제약바이오산업연구소는 제약바이오 산업에서 오랜 경험과 전문성을 갖춘 최고의 전문가들로 구성된 자문위원단 및 강사들과 함께합니다.<br />
                    연구개발, 임상시험, 규제 대응, 마케팅 등 다양한 분야에서 풍부한 현장 경험을 쌓아온 업계 리더들로,<br />
                    최신 산업 동향과 규제 변화에 대한 깊은 이해를 바탕으로, 맞춤형 컨설팅과 교육 프로그램 개발에 참여하며, 실질적이고 실행 가능한 해결책을 제시합니다.<br />
                    여러분의 성공적인 비즈니스와 전문 지식 함양을 위한 든든한 파트너가 되어드릴 것입니다.
                </motion.p>

                {
                    !isTablet &&
                    <motion.div
                        initial={START_ANIMATION_PROPERTY}
                        whileInView={END_ANIMATION_PROPERTY}
                        viewport={VIEWPORT_PROPERTY}
                    >
                        <img src={AdvisoryImg} alt="AdvisoryImg" />
                    </motion.div>
                }

            </Container>

            {
                isTablet &&
                <motion.div
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    <img src={AdvisoryImgMo} alt="AdvisoryImg" />
                </motion.div>
            }

            <Container className={`flex flex-col items-center px-4  ${isTablet ? 'py-18 gap-4' : 'py-56 gap-17.5'}`}>
                <motion.p
                    className={`${isTablet ? 'text-2xl leading-10' : 'text-40'} text-black font-bold  mb-10  text-left w-full`}
                    initial={START_ANIMATION_PROPERTY}
                    whileInView={END_ANIMATION_PROPERTY}
                    viewport={VIEWPORT_PROPERTY}
                >
                    제약바이오산업연구소 {isTablet && <br />} 자문위원단 및 강사 소개
                </motion.p>

                {
                    !isTablet ?
                        <ul className='w-full'>
                            {
                                list.map((item, idx) => (
                                    <motion.li
                                        className={`flex border-b-4 border-[#E3EDF4] p-12 ${idx === 0 && 'border-t-4'} gap-6`}
                                        key={idx}
                                        initial={START_ANIMATION_PROPERTY}
                                        whileInView={END_ANIMATION_PROPERTY}
                                        viewport={VIEWPORT_PROPERTY}
                                    >
                                        <p className='text-[#E3EDF4] text-90 flex-1' style={{ lineHeight: '83%' }}>{String(idx + 1).padStart(2, '0')}.</p>
                                        <div className='text-black flex-[8] flex gap-2 flex-col'>
                                            <div className='flex gap-3 items-baseline'>
                                                <p className='text-32 font-extrabold'>{item.name}</p>
                                                <p className='text-xl font-bold'>{item.title}</p>
                                            </div>
                                            <div className='flex gap-4 items-baseline'>
                                                <div className='flex gap-3 flex-col flex-1 2xl:flex-row 2xl:flex-none'>
                                                    <p className='text-xl font-bold'>경력</p>
                                                    {
                                                        item.career.map((career, idxs) => (
                                                            <p className='text-xl font-light' key={idxs}><span className='font-extrabold px-2'>·</span> {career}</p>
                                                        ))
                                                    }
                                                </div>
                                                {
                                                    item.education.length > 0 &&
                                                    <div className='flex gap-3 flex-col flex-1 2xl:flex-row 2xl:flex-none'>
                                                        <p className='text-xl font-bold pl-2'>학력</p>
                                                        {
                                                            item.education.map((education, idxs) => (
                                                                <p className='text-xl font-light' key={idxs}><span className='font-extrabold px-2'>·</span> {education}</p>
                                                            ))
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </motion.li>
                                ))
                            }
                        </ul> :
                        <ul className='w-full'>
                            {
                                list.map((item, idx) => (
                                    <motion.li
                                        className={`flex flex-col border-b-4 border-[#E3EDF4] text-black mb-1`}
                                        key={idx}
                                        initial={START_ANIMATION_PROPERTY}
                                        whileInView={END_ANIMATION_PROPERTY}
                                        viewport={VIEWPORT_PROPERTY}
                                    >

                                        <div className='flex items-center bg-[#E3EDF4] py-2 h-10.5'>
                                            <p className='text-base text-center w-14'>{String(idx + 1).padStart(2, '0')}</p>
                                            <div className='flex items-center px-4 gap-2'>
                                                <p className='text-base font-extrabold'>{item.name}</p>
                                                <p className='text-xs font-bold'>{item.title}</p>
                                            </div>
                                        </div>
                                        <div className='p-2'>
                                            <div className='flex flex-wrap px-2 mb-2'>
                                                {
                                                    item.career.map((career, idxs) => (
                                                        <p className='text-sm font-light mr-4' key={idxs}>
                                                            <span className='font-extrabold px-2'>·</span> {career}
                                                        </p>
                                                    ))
                                                }
                                            </div>
                                            {
                                                item.education.length > 0 &&
                                                <div className='flex flex-wrap px-2'>
                                                    {
                                                        item.education.map((education, idxs) => (
                                                            <p className='text-sm font-light mr-4' key={idxs}>
                                                                <span className='font-extrabold px-2'>·</span> {education}
                                                            </p>
                                                        ))
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </motion.li>
                                ))
                            }
                        </ul>
                }
            </Container >
        </section >
    );
}

export default Advisory;


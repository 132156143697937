export const list = [
    {
        name: '이재익',
        title: '자문 위원',
        career: ['(現)씨티내과 원장'],
        education: ['의학박사', '경영학 박사', 'MD']
    },
    {
        name: '이재영',
        title: '자문 위원',
        career: ['(前)동아ST 신약연구소 수석연구원', '(前)학술의약실 팀장'],
        education: ['약학박사', '약사']
    },
    {
        name: '최지원',
        title: '자문 위원',
        career: ['(前)Janssen RA'],
        education: ['Pharm D', '약사']
    },
    {
        name: '김세희',
        title: '자문 위원',
        career: ['(前)MSD', '(前)Sanofi', '(前)Aastazeneca', '(前)Novatis 혈액질환 AMACO 지역책임자'],
        education: ['약사']
    },
    {
        name: '이대우',
        title: '자문 위원',
        career: ['(前)Sanofi 의약부'],
        education: ['약학석사', '약사']
    },
    {
        name: '구자용',
        title: '자문 위원',
        career: ['(前)Sanofi', '(前)Boehringer 의학부', '(前)증권사 애널리스트'],
        education: ['약학박사수료', '약사']
    },
    {
        name: '임진보',
        title: '자문 위원',
        career: ['(前)화이자 MR'],
        education: ['Pharm D', '약사']
    },
    {
        name: '김한욱',
        title: '자문 위원',
        career: ['(前)SK바이오사이언스 품질관리'],
        education: ['약학박사 수료']
    },
    {
        name: '윤선미',
        title: '자문 위원',
        career: ['(前)Sanofi', '(前)AbbVie 교육팀'],
        education: ['MBA', '약사']
    },
    {
        name: '김승연',
        title: '자문 위원',
        career: ['(前)종근당', '(現)킴스제약 대표'],
        education: ['MBA', '약학박사']
    },
    {
        name: '이승준',
        title: '자문 위원',
        career: ['(前)서원대 제약공학과 교수'],
        education: ['공학박사']
    },
    {
        name: '박정호',
        title: '자문 위원',
        career: ['(現)박정호 한의원 원장'],
        education: ['약학석사', 'KMD']
    },
    {
        name: '서민준',
        title: '자문 위원',
        career: ['(前)녹십자'],
        education: ['MBA', '약학박사 수료']
    },
    {
        name: '박장석',
        title: '자문 위원',
        career: ['(現)체인익스빌 대표'],
        education: []
    },
    {
        name: '박종현',
        title: '자문 위원',
        career: ['(現)체인익스빌 이사'],
        education: []
    },
    {
        name: '정주태',
        title: '자문 위원',
        career: ['(現)화장품 임상센터'],
        education: ['약학박사 수료']
    },
    {
        name: '김상아',
        title: '자문 위원',
        career: ['(現)화장품 임상센터'],
        education: ['약학석사']
    }
];
